export const userStates = [
  { label: 'NSW', value: 'NSW' },
  { label: 'VIC', value: 'VIC' },
  { label: 'QLD', value: 'QLD' },
  { label: 'WA', value: 'WA' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'NT', value: 'NT' },
  { label: 'ACT', value: 'ACT' }
]

export const printableStates = [
  { label: 'NSW/ACT', value: 'nsw' },
  { label: 'VIC/WA/NT', value: 'vic' },
  { label: 'QLD', value: 'qld' },
  { label: 'SA', value: 'sa' },
  { label: 'TAS', value: 'tas' },
  { label: 'Print', value: 'print' }
]
